const en = {
  homeTitle: 'Get gut geregelt',
  homeDescription:
    'We are here to help you and your loved ones. However, sometimes there are special circumstances that you may want to discuss with an expert beforehand.',
  homeHighlight: 'discuss with an expert ',

  clear: 'Clear',
  search: 'Search',

  noData: 'No data',

  customerOpinion: 'Customer opinion',
  joinWaitlist: 'Join waitlist',

  civilStatus: {
    married: 'Married',
    single: 'Single',
    divorced: 'Divorced',
    widowed: 'Widowed',
    registered_partnership: 'Registered partnership',
  },

  dashCardInfos: {
    livingWill: {
      title: 'Living will',
      description: '80% people have their living will, within 10 minutes',
    },
    powerOfAttorney: {
      title: 'Power of attorney',
      description: 'We expect that this should take you 5 minutes',
    },
    lastWill: {
      title: 'Last will',
      description: '80% people have their last will, within 10 minutes',
    },
    digitalFootprint: {
      title: 'Digital footprint',
      description: 'Leaving a clean digital slate.',
    },
    assetsRegister: {
      title: 'Register of assets',
      description: 'Digitally mapping your assets.',
    },
    successionPlanning: {
      title: 'Succession planning',
      description: 'You own a business?! Ensure its sustainability.',
    },
    comingSoon: {
      title: 'Coming soon',
      description:
        'gut geregelt is constantly working on furthe innovative services.',
    },
    continue: 'Continue',
    getStarted: 'Get started',
    summaryAndReview: 'View document',
    update: 'Update',
    history: 'History',
    markAsDone: 'Mark as done',
    guest: {
      disabledButton: 'Host need to finish and download the document first',
      getDocButton: 'Get document',
    },
  },

  loginInfo: {
    title: 'Do your loved ones a favor, get gutgeregelt',
    title1: 'Hi, welcome back',
    newUser: 'New user?',
    createAccount: 'Create account',
    forgotPassword: 'Forgot password?',
    login: 'Login',
    resetPasswordInfo:
      'Please enter the email address associated with your account and We will email you a link to reset your password.',
    sendRequest: 'Send request',
    returnToLogin: 'Return to login',
    setNewPassword: 'Set new password',
    updatePassword: 'Update password',
    invalidLink: 'Link is invalid or expired !',
    returnToResetPassword: 'Return to reset password',
  },

  services: {
    forIndividuals: 'For individuals',
    forCompanies: 'For companies',
    livingWill: 'Living will',
    powerOfAttorney: 'Power of attorney',
    lastWill: 'Last will',
    digitalFootprint: 'Digital footprint',
    registerAssets: 'Register of assets',
    pricing: 'Pricing',
    login: 'Login',
    makeProvisions: 'Make provisions',
    partnerWithUs: 'Partner with us',
    blog: 'Blog',
    dashboard: 'Dashboard',
    consulting: 'Consulting',
  },

  settings: {
    changeAccount: 'Change account',
    changeAccountContent: {
      title: 'Switch to another account',
    },
    settings: 'Settings',
    needHelp: 'Need help?',
    logOut: 'Logout',
  },

  roles: {
    executor: 'Executor',
    lawyer: 'Lawyer',
    trustee: 'Trustee',
    familyMember: 'Family member',
    friend: 'Friend',
  },

  account: 'Account',
  main: 'Main',
  guest: 'Guest',
  lastUpdated: 'Last update:',
  description: 'Description',
  imageHelper: 'Allowed *.jpeg, *.jpg, *.png, *.gif, max size of 10.0 MB',
  imageMaxSize: 'maximum file size: ',
  storageLocation: 'Storage location',
  beneficiary: 'Beneficiary',
  beneficiarie: 'Beneficiary',
  addNewBeneficiary: 'Add new beneficiary',
  primary: 'Primary',
  representative: 'Representative',
  enoughForNow: 'Break please!',
  finishForToday: 'Finish for today',
  currentPackage: 'Your current purchase package :',
  freePackage: 'Free package',
  starterPackage: 'Starter package',
  standardPackage: 'Standard package',
  familyPack: 'Family package',
  noPackage: 'No package',
  needHelp: 'Need help?',
  continueToLastWill: 'Continue to last will',
  continueToPOA: 'Continue to power of attorney',
  addAnother: 'Add another',
  add: 'Add',
  finish: 'Finish',
  delete: 'Delete',
  edit: 'Edit',
  save: 'Save',
  next: 'Next',
  back: 'Back',
  or: 'or',
  role: 'Role',
  current: 'Current',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  cancel: 'Cancel',
  priority: 'Priority',
  areYouSureYouWantToLeave: 'Are you sure you want to take a break?',
  enoughForNowDscp:
    "Don't worry, everything you have completed is saved automatically, and you will be reminded to continue where you paused.",
  enoughForNowCancel: "Yes, I'm done",
  enoughForNowContinue: 'Thanks, I want to continue',
  addPropertyOrAsset: 'Add property or asset',
  areYouSureYouWantToDeleteRep:
    'Are you sure you want to delete this representative?',
  repDeleteDscp:
    'Deleting this representative will also delete all the information you have entered for them.',
  repDeleteCancel: 'No, keep representative',
  repDeleteDelete: 'Yes, delete representative',

  addItem: 'Add item',
  good: 'good',
  adress: 'Address',
  download: 'Download',
  upgradePlan: 'Upgrade your plan to download 2 or more documents',
  choosePlan: 'Choose plan',
  switzerland: 'Switzerland',
  fillInfoTitle:
    'Please make sure to fill important info for your primary account',
  switch: 'Switch',
  activatePersonalAccount: 'Activate your personal account',
  howManyMembers: 'Family members',
  requiredBasicInfoTitle:
    'Please fill in the required basic information before proceeding',

  deleteConfirmation: {
    question: 'Are you sure you want to delete this {{item}} ?',
    cancelDelete: 'No, keep {{item}}',
    confirmDelete: 'Yes, delete {{item}}',
  },

  familyPackage: {
    title: 'My family',
    description:
      'Having family package allows you to invite your family members to gut geregelt.',
    helperText:
      'Please carefully fill in the information of your family members. This information cannot be updated once the user accepts invitation.',
  },

  formLabels: {
    name: 'Name',
    firstName: 'First name',
    lastName: 'Last name',
    street: 'Street',
    houseNumber: 'House number',
    civilStatus: 'Civil status',
    postalCode: 'Postal code',
    city: 'Town / City',
    country: 'Country',
    uploadPhoto: 'Upload photo',
    uploadLogo: 'Upload logo',
    submit: 'Submit',
    invite: 'Invite',
    emailAddress: 'Email address',
    mobilePhone: 'Mobile phone',
    role: 'Role',
    search: 'Search',
    password: 'Password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    minimum6Characters: 'Minimum 6 characters',
    primaryRepresentative: 'Primary representative',
    secondaryRepresentative: 'Secondary representative',
    howManyChildren: 'How many children do you have?',
    date_of_birth: 'Date of birth',
    rep_inform_date: 'Inform date',
    trusted_person: 'Trusted person',
    location: 'Location',
    message: 'Message',
    domain: 'Domain',
    addWidget: 'Add widget',
    plusWidget: '+ Widget',
    organisation_name: 'Organisation name',
    primary_color: 'Primary color',
    secondary_color: 'Secondary color',
    sendAutomatically: 'Automatically when I update the living will',
    deathReport: 'Death report',
    executorCertificate: 'Executor certificate',
    trustees_list: {
      husband: 'Husband',
      wife: 'Wife',
      partner: 'Partner',
      'close-friend': 'Close friend',
      brother: 'Brother',
      'brother-in-law': 'Brother-in-law',
      sister: 'Sister',
    },
    beneficiarieCategories: {
      selectCategory: 'Select category',
      individual: 'Individual',
      company: 'Company',
      foundation: 'Foundation',
      association: 'Association',
    },
    ordinalNumbers: {
      first: 'first',
      second: 'second',
      third: 'third',
      fourth: 'fourth',
      fifth: 'fifth',
      sixth: 'sixth',
      seventh: 'seventh',
      eighth: 'eighth',
      ninth: 'ninth',
      tenth: 'tenth',
      information: 'Information about {{ordinalNumber}} child',
    },
  },

  userSettings: {
    general: 'General',
    billing: 'Billing',
    my_family: 'My family',
    successors: 'Successors',
    notifications: 'Notifications',
    changePassword: 'Change password',
    changeReminder:
      'Change frequency of reminders when a process is interrupted',
    reminder: 'Reminder',
    reminderFrequency: 'Reminder frequency',
    frequencyChoices: {
      weekly: 'Weekly',
      monthly: 'Monthly',
      quarterly: 'Quarterly',
      disabled: 'Disable',
    },
    successor: {
      title: 'Define successors',
      description:
        'To ensure that your documents and information are available in the event of your death, you should make sure that at least two people have access to gut geregelt.',
      pleaseConfirm:
        'Please confirm that the following people get guest access from today on (with limited access - see what is included) and will have unrestricted access to all data in the event of your death.',
      agree: 'Yes, I agree',
      addSuccessor: 'Add successor',
    },
  },

  adminSettings: {
    general: 'General',
    team: 'Team',
    consulting: 'Consulting',
    changePassword: 'Change password',
  },

  team: {
    tableHeads: {
      name: 'Name',
      email: 'Email',
      role: 'Role',
      last_login: 'Last login',
    },
    resendInvitation: 'Resend invitation',
    cancelInvitation: 'Cancel invite',
    consultant: 'Consultant',
    consultantDescription: 'Access to gut geregelt consultant cockpit.',
    admin: 'Admin',
    adminDescription:
      'Manage gut geregelt cockpit, people, and other settings.',
  },

  footer: {
    allRightsReserved: 'All rights reserved',
    provisionLinks: {
      title: 'Provisions',
      pricing: 'Pricing',
      livingWill: 'Living will',
      powerOfAttorney: 'Power of attorney',
      lastWill: 'Last will',
      digitalFootprint: 'Digital footprint',
      registerAssets: 'Register of assets',
    },
    resourcesLinks: {
      title: 'Resources',
      imprint: 'Imprint',
      termsAndConditions: 'Terms and conditions',
      privacyPolicy: 'Privacy policy',
      contact: 'Contact',
    },
    contact: 'Contact',
  },

  contactUs: {
    getLegalSupport: 'Get legal support',
    description:
      'gut geregelt offers direct and personal legal advice if desired please simply use our contact\n' +
      'form:',
    pleaseWriteMessage: 'Please write your message here...',
  },

  civil_statusWarning: {
    title: 'Are you sure you want to update your civil status?',
    description:
      'If you change your civil status, all your Testament data will be deleted and you will have to start over.',
    cancel: 'Cancel',
    update: 'It`s okay, change it',
  },
  hyperlinks: {
    lastWill: 'the last will',
    livingWill: 'the living will',
    powerOfAttorney: 'the power of attorney',
  },

  signedDoc: {
    placeholder: {
      headingText: 'Upload after signing',
      currentlySigned: 'Currently signed and active {{docType}}',
      grammarFix: {
        livingWill: 'signed and active',
        powerOfAttorney: 'signed and active',
        lastWill: 'signed and active',
      },
      doctype: {
        lastWill: 'Last will',
        livingWill: 'Living will',
        powerOfAttorney: 'Power of attorney',
      },
      helper: {
        dropFilesHere: 'Drop files here or click',
        browse: 'browse',
        thoroughYourDevice: 'thorough your device',
      },
    },
    reUpload: 'Drag and drop or click to re-upload',
    updatedAt: 'Updated at  {{date}}',
  },

  shareDocument: {
    title: 'Share it with your family doctor',
    description:
      'We recommend that you share the signed version of your living will with your family doctor.',
    doAutomatically: 'Automatically when I update the living will',
  },

  finfinder: {
    title: 'Choose your financial advisor on FinFinder',
    button: 'Arrange a free initial consultation',
  },

  consulting: {
    title: 'Consulting',
    description: 'Support your customers with their pension provision.',
    dashCardInfos: {
      livingWill: {
        title: 'Living will',
        description:
          'Regulates which medical treatments the customer agrees to or refuses if they are no longer able to decide for themselves.',
      },
      powerOfAttorney: {
        title: 'Power of attorney',
        description:
          'Defines who legally represents the customer in the worst-case scenario, assumes their rights and obligations towards the family and takes care of their financial affairs.',
      },
      lastWill: {
        title: 'Last will',
        description:
          "Provides information on the client's wishes regarding the inheritance of assets.",
      },
      ageAppropriate: {
        title: 'Age-appropriate 3a investing',
        description:
          'Check the extent to which your client invests the 3a assets in an age-appropriate manner and take appropriate measures if necessary.',
      },
      ahvCalculator: {
        title: 'AHV calculator',
        description:
          'The AHV calculator is a pension estimate and is based on a simplified calculation method that is not binding.',
      },
      lifeExpectancy: {
        title: 'Life expectancy',
        description:
          'Calculates the theoretical life expectancy of the client based off actuarial tables.',
      },
      getStarted: 'Get started',
      unlock: 'Unlock',
      createUser: 'Create user',
      createUserDescription:
        'Create a user for your customer that they can continue to use independently of you to view documents and make adjustments.',
    },
    pageTitle: 'Create client login',
    createUserForm: {
      description:
        'Your customer will receive a separate email with access data. Ask your customer to change the communicated password when logging in for the first time.',
    },
  },

  consultantDashboard: {
    pageName: 'Consultant Dashboard',
    title: 'Dashboard',
    description:
      'Get an overview of your customer portfolio and its status with regard to pension provision with gut geregelt.',
  },
};

export default en;
