import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';

type Props = {
  title: string | undefined;
  menuItems: Array<{ title: string; url: string; auth?: boolean }> | undefined;
};

export default function MegaMenuItem({ title, menuItems }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { translate } = useLocales();
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box onMouseEnter={handleOpen} onMouseLeave={handleClose}>
      <Stack
        component="nav"
        direction="row"
        alignItems="center"
        sx={{
          color: 'text.primary',
          opacity: 0.5,
        }}
      >
        <Typography noWrap>{String(translate(title))}</Typography>
        {open ? (
          <Iconify icon="eva:arrow-ios-downward-fill" />
        ) : (
          <Iconify icon="eva:arrow-ios-upward-fill" />
        )}
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
      >
        <Stack spacing={0.75}>
          {menuItems?.map((option) => (
            <MenuItem
              key={option.title}
              onClick={() => {
                navigate(option.url);
                handleClose();
              }}
            >
              <Typography>{String(translate(option.title))}</Typography>
            </MenuItem>
          ))}
        </Stack>
      </Menu>
    </Box>
  );
}
