import './locales/i18n';
import 'react-international-phone/style.css';
import 'tippy.js/dist/tippy.css';

import ReactDOM from 'react-dom/client';

import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import App from './App';
import { SettingsProvider } from './context/SettingsContext';
import { AuthProvider } from './context/JWTContext';
import queryClient from '#/api/query-client';
import { ServiceStatsProvider } from '#/context/ServiceStats';
import reportWebVitals from './reportWebVitals';
import { AccountSwitchProvider } from '#/context/AccountSwitchContext';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AccountSwitchProvider>
          <SettingsProvider>
            <BrowserRouter>
              <Toaster position="top-right" reverseOrder={false} />
              <ServiceStatsProvider>
                <App />
              </ServiceStatsProvider>
            </BrowserRouter>
          </SettingsProvider>
        </AccountSwitchProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </HelmetProvider>
);

reportWebVitals();
