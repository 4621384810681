import { useDropzone } from 'react-dropzone';
import {
  Box,
  Stack,
  Button,
  IconButton,
  Typography,
  StackProps,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { UploadProps } from './types';
import RejectionFiles from './errors/RejectionFiles';
import MultiFilePreview from './preview/MultiFilePreview';
// import SingleFilePreview from './preview/SingleFilePreview';
import Iconify from '../ui/Iconify';
import useLocales from '#/hooks/useLocales';

const StyledDropZone = styled('div')(({ theme }) => ({
  outline: 'none',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
  '&:hover': {
    opacity: 0.72,
  },
}));

export default function UploadBasicInput({
  disabled,
  multiple = false,
  error,
  helperText,
  file,
  onDelete,
  files,
  thumbnail,
  onUpload,
  onRemove,
  onRemoveAll,
  sx,
  placeholderHeading,
  ...other
}: UploadProps) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple,
    disabled,
    ...other,
  });

  const isCustomFile = typeof file !== 'string';

  const hasFile = !!file && !multiple;

  const hasFiles = files && multiple && files.length > 0;

  const isError = isDragReject || !!error;
  // const isImageFile = isCustomFile ? file?.type.includes('image') : false;

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }}>
      <StyledDropZone
        {...getRootProps()}
        sx={{
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(isCustomFile && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          }),
          ...(isError && {
            color: 'error.main',
            bgcolor: 'error.lighter',
            borderColor: 'error.light',
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),
        }}
      >
        <input {...getInputProps()} />

        <Placeholder
          heading={placeholderHeading}
          sx={{
            ...(hasFile && {
              opacity: 0,
              display: 'none',
            }),
          }}
        />

        {hasFile && (
          // <SingleFilePreview
          //   file={file}
          //   helperText={String(translate('global.signedDoc.reUpload'))}
          // />
          // @ts-ignore
          <Placeholder fileName={file?.name} />
        )}
      </StyledDropZone>

      {helperText && helperText}

      <RejectionFiles fileRejections={fileRejections} />

      {hasFile && onDelete && (
        <IconButton
          size="small"
          onClick={onDelete}
          sx={{
            top: 25,
            right: 0,
            zIndex: 9,
            position: 'absolute',
            color: (theme) => alpha(theme.palette.common.white, 0.8),
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
            },
          }}
        >
          <Iconify icon="eva:close-fill" width={18} />
        </IconButton>
      )}

      {hasFiles && (
        <>
          <Box sx={{ my: 3 }}>
            <MultiFilePreview
              files={files}
              thumbnail={thumbnail}
              onRemove={onRemove}
            />
          </Box>

          <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
            {onRemoveAll && (
              <Button
                color="inherit"
                variant="outlined"
                size="small"
                onClick={onRemoveAll}
              >
                Remove all
              </Button>
            )}

            {onUpload && (
              <Button size="small" variant="contained" onClick={onUpload}>
                Upload files
              </Button>
            )}
          </Stack>
        </>
      )}
    </Box>
  );
}

interface PlaceholderProps extends StackProps {
  heading?: string;
  fileName?: string;
}

function Placeholder({ sx, heading, fileName, ...other }: PlaceholderProps) {
  const { translate } = useLocales();

  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        width: 1,
        textAlign: {
          xs: 'center',
          md: 'left',
        },
        ...sx,
      }}
      {...other}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        justifyContent="space-between"
      >
        <Iconify
          icon={fileName ? 'lets-icons:check-round-fill' : 'ic:round-upload'}
          width={24}
          color={
            fileName ? (theme) => theme.palette.primary.main : 'text.secondary'
          }
        />
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
          noWrap
          width={{
            xs: '100%',
            md: '120px',
          }}
        >
          {fileName || String(translate('global.formLabels.uploadLogo'))}
        </Typography>
      </Stack>
    </Stack>
  );
}
