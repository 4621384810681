export type FAQData = {
  service: string;
  questions: {
    question: string;
    answer: string;
  }[];
};

export const landingBanners = [
  {
    title: 'main',
    bannerIntro: 'provisionLanding.mainBanner.intro',
    bannerHeading: 'provisionLanding.mainBanner.title',
    bannerSubHeading: 'provisionLanding.mainBanner.subtitle',
    bannerImage: '/assets/images/mainBanner.webp',
    bannerButton: 'provisionLanding.mainBanner.buttonLabel',
    url: '/vorsorge',
    youtubePlayer: {
      url: {
        en: 'https://www.youtube.com/watch?v=drSlIbDZnnM',
        de: 'https://youtu.be/sr2xxoG8C4E',
        fr: 'https://www.youtube.com/watch?v=RcEoaUFgm1o',
      },
    },
    trustLaurel: true,
  },
  {
    title: 'patientenverfuegung',
    bannerIntro: 'livingWillLanding.mainBanner.intro',
    bannerHeading: 'livingWillLanding.mainBanner.title',
    bannerSubHeading: 'livingWillLanding.mainBanner.subtitle',
    bannerImage: '/assets/images/livingWillBanner.webp',
    bannerButton: 'livingWillLanding.mainBanner.buttonLabel',
    url: '/vorsorge',
    youtubePlayer: {
      url: {
        de: 'https://youtu.be/IVoqgpeknPw',
        fr: 'https://www.youtube.com/watch?v=rEBtYQLkcMc',
      },
    },
    trustLaurel: true,
  },
  {
    title: 'vorsorgeauftrag',
    bannerIntro: 'poaLanding.mainBanner.intro',
    bannerHeading: 'poaLanding.mainBanner.title',
    bannerSubHeading: 'poaLanding.mainBanner.subtitle',
    bannerImage: '/assets/images/powerOfAttorneyBanner.webp',
    bannerButton: 'poaLanding.mainBanner.buttonLabel',
    url: '/vorsorge',
    youtubePlayer: {
      url: {
        de: 'https://youtu.be/FIv9F1OIArc',
        fr: 'https://www.youtube.com/watch?v=BymZ0rTMo_I',
      },
    },
    trustLaurel: true,
  },
  {
    title: 'testament',
    bannerIntro: 'lastWillLanding.mainBanner.intro',
    bannerHeading: 'lastWillLanding.mainBanner.title',
    bannerSubHeading: 'lastWillLanding.mainBanner.subtitle',
    bannerImage: '/assets/images/lastWillBanner.webp',
    bannerButton: 'lastWillLanding.mainBanner.buttonLabel',
    url: '/vorsorge',
    youtubePlayer: {
      url: {
        de: 'https://youtu.be/Ex_ePTW2Rlo',
        fr: 'https://www.youtube.com/watch?v=xmMPN8ZFp0o',
      },
    },
    trustLaurel: true,
  },
  {
    title: 'ehevertrag',
    bannerIntro: 'marriageContractLanding.mainBanner.intro',
    bannerHeading: 'marriageContractLanding.mainBanner.title',
    bannerSubHeading: 'marriageContractLanding.mainBanner.subtitle',
    bannerImage: '/assets/images/marriageContractBanner.webp',
    bannerButton: 'marriageContractLanding.mainBanner.buttonLabel',
    url: '/vorsorge',
    youtubePlayer: {
      url: {
        de: 'https://youtu.be/5cF4sn7bQgA',
        fr: 'https://www.youtube.com/watch?v=xmMPN8ZFp0o',
      },
    },
    trustLaurel: true,
  },
  {
    title: 'partner-werden',
    bannerHeading: 'partnerWithUs.mainBanner.title',
    bannerSubHeading: 'partnerWithUs.mainBanner.subtitle',
    bannerImage: '/assets/images/partnerWithUsBanner.webp',
    bannerButton: 'partnerWithUs.mainBanner.buttonLabel',
    url: '#partner-with-us',
    youtubePlayer: {
      url: {
        en: 'https://www.youtube.com/watch?v=8SV9VgrQtSo&ab_channel=gutgeregelt',
        de: 'https://youtu.be/HtRcV8Up0YM',
        fr: 'https://youtu.be/WS83PlxEkyM',
        it: 'https://youtu.be/eqCBc5bh4bI',
      },
    },
    trustLaurel: true,
  },
  {
    title: 'digitaler-nachlass',
    bannerHeading: 'digitalFootprintLanding.mainBanner.title',
    bannerSubHeading: 'digitalFootprintLanding.mainBanner.subtitle',
    bannerImage: '/assets/images/digitalFootprintBanner.webp',
    bannerButton: 'digitalFootprintLanding.mainBanner.buttonLabel',
    url: '/vorsorge',
    youtubePlayer: {
      url: {
        en: 'https://youtu.be/yan5V0-bhIQ',
        de: 'https://youtu.be/yan5V0-bhIQ',
        fr: 'https://youtu.be/yan5V0-bhIQ',
      },
    },
    trustLaurel: true,
  },
  {
    title: 'vermoegens-und-sachregister',
    bannerHeading: 'registerAssets.mainBanner.title',
    bannerSubHeading: 'registerAssets.mainBanner.subtitle',
    bannerImage: '/assets/images/registerAssetsBanner.webp',
    bannerButton: 'registerAssets.mainBanner.buttonLabel',
    url: '/vorsorge',
    youtubePlayer: {
      url: {
        en: 'https://youtu.be/GZL97YOaevM',
        de: 'https://youtu.be/GZL97YOaevM',
        fr: 'https://youtu.be/GZL97YOaevM',
      },
    },
    trustLaurel: true,
    waitlist: false,
  },
  {
    title: 'grosseltern-magazin',
    bannerIntro: 'grosseItern.mainBanner.intro',
    bannerHeading: 'grosseItern.mainBanner.title',
    bannerSubHeading: 'grosseItern.mainBanner.subtitle',
    bannerImage: '/assets/images/grosseIternBanner.webp',
    bannerButton: 'grosseItern.mainBanner.buttonLabel',
    url: '/vorsorge',
    trustLaurel: true,
  },
];

export const youtubeTutorials = [
  {
    title: 'provisionLanding.youtubeTutorial',
    service: 'main',
    url: {
      en: 'https://www.youtube.com/watch?v=drSlIbDZnnM',
      de: 'https://youtu.be/sr2xxoG8C4E',
      fr: 'https://www.youtube.com/watch?v=RcEoaUFgm1o',
    },
  },
  {
    title: 'livingWillLanding.youtubeTutorial',
    service: 'patientenverfuegung',
    url: {
      de: 'https://youtu.be/IVoqgpeknPw',
      fr: 'https://www.youtube.com/watch?v=rEBtYQLkcMc',
    },
  },
  {
    title: 'poaLanding.youtubeTutorial',
    service: 'vorsorgeauftrag',
    url: {
      de: 'https://youtu.be/FIv9F1OIArc',
      fr: 'https://www.youtube.com/watch?v=BymZ0rTMo_I',
    },
  },
  {
    title: 'lastWillLanding.youtubeTutorial',
    service: 'testament',
    url: {
      de: 'https://youtu.be/Ex_ePTW2Rlo',
      fr: 'https://www.youtube.com/watch?v=xmMPN8ZFp0o',
    },
  },
  {
    title: 'marriageContractLanding.youtubeTutorial',
    service: 'ehevertrag',
    url: {
      de: 'https://youtu.be/5cF4sn7bQgA',
      fr: 'https://www.youtube.com/watch?v=xmMPN8ZFp0o',
    },
  },
  {
    title: 'partnerWithUs.youtubeTutorial',
    service: 'partner-werden',
    url: {
      en: 'https://www.youtube.com/watch?v=8SV9VgrQtSo&ab_channel=gutgeregelt',
      de: 'https://youtu.be/HtRcV8Up0YM',
      fr: 'https://youtu.be/WS83PlxEkyM',
      it: 'https://youtu.be/eqCBc5bh4bI',
    },
  },
  {
    title: 'digitalFootprintLanding.youtubeTutorial',
    service: 'digitaler-nachlass',
    url: {
      en: 'https://youtu.be/yan5V0-bhIQ',
      de: 'https://youtu.be/yan5V0-bhIQ',
      fr: 'https://youtu.be/yan5V0-bhIQ',
    },
  },
  {
    title: 'registerAssets.youtubeTutorial',
    service: 'vermoegens-und-sachregister',
    url: {
      en: 'https://youtu.be/GZL97YOaevM',
      de: 'https://youtu.be/GZL97YOaevM',
      fr: 'https://youtu.be/GZL97YOaevM',
    },
  },
];

export const eightReasons = [
  {
    title: 'provisionLanding.eightReasons.cards.card1.title',
    description: (service: string) =>
      `provisionLanding.eightReasons.cards.card1.description.${service}`,
    icon: '/assets/icons/whyUs1.svg',
  },
  {
    title: 'provisionLanding.eightReasons.cards.card2.title',
    description: (service: string) =>
      `provisionLanding.eightReasons.cards.card2.description.${service}`,
    icon: '/assets/icons/whyUs2.svg',
  },
  {
    title: 'provisionLanding.eightReasons.cards.card3.title',
    description: (service: string) =>
      `provisionLanding.eightReasons.cards.card3.description.${service}`,
    icon: '/assets/icons/conveniently.svg',
  },
  {
    title: 'provisionLanding.eightReasons.cards.card4.title',
    description: (service: string) =>
      `provisionLanding.eightReasons.cards.card4.description.${service}`,
    icon: '/assets/icons/costumisable.svg',
  },
  {
    title: 'provisionLanding.eightReasons.cards.card5.title',
    description: (service: string) =>
      `provisionLanding.eightReasons.cards.card5.description.${service}`,
    icon: '/assets/icons/enforceWishes.svg',
  },
  {
    title: 'provisionLanding.eightReasons.cards.card6.title',
    description: (service: string) =>
      `provisionLanding.eightReasons.cards.card6.description.${service}`,
    icon: '/assets/icons/mutualProtection.svg',
  },
  {
    title: 'provisionLanding.eightReasons.cards.card7.title',
    description: (service: string) =>
      `provisionLanding.eightReasons.cards.card7.description.${service}`,
    icon: '/assets/icons/access.svg',
  },
  {
    title: 'provisionLanding.eightReasons.cards.card8.title',
    description: (service: string) =>
      `provisionLanding.eightReasons.cards.card8.description.${service}`,
    icon: '/assets/icons/whyUs3.svg',
  },
];

export const faqData: FAQData[] = [
  {
    service: 'patientenverfuegung',
    questions: [
      {
        question: 'livingWillLanding.faq.questions.question1',
        answer: 'livingWillLanding.faq.answers.answer1',
      },
      {
        question: 'livingWillLanding.faq.questions.question2',
        answer: 'livingWillLanding.faq.answers.answer2',
      },
      {
        question: 'livingWillLanding.faq.questions.question3',
        answer: 'livingWillLanding.faq.answers.answer3',
      },
      {
        question: 'livingWillLanding.faq.questions.question4',
        answer: 'livingWillLanding.faq.answers.answer4',
      },
      {
        question: 'livingWillLanding.faq.questions.question5',
        answer: 'livingWillLanding.faq.answers.answer5',
      },
      {
        question: 'livingWillLanding.faq.questions.question6',
        answer: 'livingWillLanding.faq.answers.answer6',
      },
      {
        question: 'livingWillLanding.faq.questions.question7',
        answer: 'livingWillLanding.faq.answers.answer7',
      },
      {
        question: 'livingWillLanding.faq.questions.question8',
        answer: 'livingWillLanding.faq.answers.answer8',
      },
      {
        question: 'livingWillLanding.faq.questions.question9',
        answer: 'livingWillLanding.faq.answers.answer9',
      },
      {
        question: 'livingWillLanding.faq.questions.question10',
        answer: 'livingWillLanding.faq.answers.answer10',
      },
      {
        question: 'livingWillLanding.faq.questions.question11',
        answer: 'livingWillLanding.faq.answers.answer11',
      },
      {
        question: 'livingWillLanding.faq.questions.question12',
        answer: 'livingWillLanding.faq.answers.answer12',
      },
      {
        question: 'livingWillLanding.faq.questions.question13',
        answer: 'livingWillLanding.faq.answers.answer13',
      },
      {
        question: 'livingWillLanding.faq.questions.question14',
        answer: 'livingWillLanding.faq.answers.answer14',
      },
      {
        question: 'livingWillLanding.faq.questions.question15',
        answer: 'livingWillLanding.faq.answers.answer15',
      },
      {
        question: 'livingWillLanding.faq.questions.question16',
        answer: 'livingWillLanding.faq.answers.answer16',
      },
      {
        question: 'livingWillLanding.faq.questions.question17',
        answer: 'livingWillLanding.faq.answers.answer17',
      },
      {
        question: 'livingWillLanding.faq.questions.question18',
        answer: 'livingWillLanding.faq.answers.answer18',
      },
      {
        question: 'livingWillLanding.faq.questions.question19',
        answer: 'livingWillLanding.faq.answers.answer19',
      },
      {
        question: 'livingWillLanding.faq.questions.question20',
        answer: 'livingWillLanding.faq.answers.answer20',
      },
      {
        question: 'livingWillLanding.faq.questions.question21',
        answer: 'livingWillLanding.faq.answers.answer21',
      },
      {
        question: 'livingWillLanding.faq.questions.question22',
        answer: 'livingWillLanding.faq.answers.answer22',
      },
    ],
  },
  {
    service: 'vorsorgeauftrag',
    questions: [
      {
        question: 'poaLanding.faq.questions.question1',
        answer: 'poaLanding.faq.answers.answer1',
      },
      {
        question: 'poaLanding.faq.questions.question2',
        answer: 'poaLanding.faq.answers.answer2',
      },
      {
        question: 'poaLanding.faq.questions.question3',
        answer: 'poaLanding.faq.answers.answer3',
      },
      {
        question: 'poaLanding.faq.questions.question4',
        answer: 'poaLanding.faq.answers.answer4',
      },
      {
        question: 'poaLanding.faq.questions.question5',
        answer: 'poaLanding.faq.answers.answer5',
      },
      {
        question: 'poaLanding.faq.questions.question6',
        answer: 'poaLanding.faq.answers.answer6',
      },
      {
        question: 'poaLanding.faq.questions.question7',
        answer: 'poaLanding.faq.answers.answer7',
      },
      {
        question: 'poaLanding.faq.questions.question8',
        answer: 'poaLanding.faq.answers.answer8',
      },
      {
        question: 'poaLanding.faq.questions.question9',
        answer: 'poaLanding.faq.answers.answer9',
      },
      {
        question: 'poaLanding.faq.questions.question10',
        answer: 'poaLanding.faq.answers.answer10',
      },
      {
        question: 'poaLanding.faq.questions.question11',
        answer: 'poaLanding.faq.answers.answer11',
      },
      {
        question: 'poaLanding.faq.questions.question12',
        answer: 'poaLanding.faq.answers.answer12',
      },
    ],
  },
  {
    service: 'testament',
    questions: [
      {
        question: 'lastWillLanding.faq.questions.question1',
        answer: 'lastWillLanding.faq.answers.answer1',
      },
      {
        question: 'lastWillLanding.faq.questions.question2',
        answer: 'lastWillLanding.faq.answers.answer2',
      },
      {
        question: 'lastWillLanding.faq.questions.question3',
        answer: 'lastWillLanding.faq.answers.answer3',
      },
      {
        question: 'lastWillLanding.faq.questions.question4',
        answer: 'lastWillLanding.faq.answers.answer4',
      },
      {
        question: 'lastWillLanding.faq.questions.question5',
        answer: 'lastWillLanding.faq.answers.answer5',
      },
      {
        question: 'lastWillLanding.faq.questions.question6',
        answer: 'lastWillLanding.faq.answers.answer6',
      },
      {
        question: 'lastWillLanding.faq.questions.question7',
        answer: 'lastWillLanding.faq.answers.answer7',
      },
      {
        question: 'lastWillLanding.faq.questions.question8',
        answer: 'lastWillLanding.faq.answers.answer8',
      },
      {
        question: 'lastWillLanding.faq.questions.question9',
        answer: 'lastWillLanding.faq.answers.answer9',
      },
      {
        question: 'lastWillLanding.faq.questions.question10',
        answer: 'lastWillLanding.faq.answers.answer10',
      },
      {
        question: 'lastWillLanding.faq.questions.question11',
        answer: 'lastWillLanding.faq.answers.answer11',
      },
      {
        question: 'lastWillLanding.faq.questions.question12',
        answer: 'lastWillLanding.faq.answers.answer12',
      },
      {
        question: 'lastWillLanding.faq.questions.question13',
        answer: 'lastWillLanding.faq.answers.answer13',
      },
    ],
  },
  {
    service: 'ehevertrag',
    questions: [
      {
        question: 'marriageContractLanding.faq.questions.question1',
        answer: 'marriageContractLanding.faq.answers.answer1',
      },
      {
        question: 'marriageContractLanding.faq.questions.question2',
        answer: 'marriageContractLanding.faq.answers.answer2',
      },
      {
        question: 'marriageContractLanding.faq.questions.question3',
        answer: 'marriageContractLanding.faq.answers.answer3',
      },
      {
        question: 'marriageContractLanding.faq.questions.question4',
        answer: 'marriageContractLanding.faq.answers.answer4',
      },
      {
        question: 'marriageContractLanding.faq.questions.question5',
        answer: 'marriageContractLanding.faq.answers.answer5',
      },
      {
        question: 'marriageContractLanding.faq.questions.question6',
        answer: 'marriageContractLanding.faq.answers.answer6',
      },
    ],
  },
  {
    service: 'partner-werden',
    questions: [
      {
        question: 'partnerWithUs.faq.questions.question1',
        answer: 'partnerWithUs.faq.answers.answer1',
      },
      {
        question: 'partnerWithUs.faq.questions.question2',
        answer: 'partnerWithUs.faq.answers.answer2',
      },
      {
        question: 'partnerWithUs.faq.questions.question3',
        answer: 'partnerWithUs.faq.answers.answer3',
      },
      {
        question: 'partnerWithUs.faq.questions.question4',
        answer: 'partnerWithUs.faq.answers.answer4',
      },
      {
        question: 'partnerWithUs.faq.questions.question5',
        answer: 'partnerWithUs.faq.answers.answer5',
      },
      {
        question: 'partnerWithUs.faq.questions.question6',
        answer: 'partnerWithUs.faq.answers.answer6',
      },
      {
        question: 'partnerWithUs.faq.questions.question7',
        answer: 'partnerWithUs.faq.answers.answer7',
      },
      {
        question: 'partnerWithUs.faq.questions.question8',
        answer: 'partnerWithUs.faq.answers.answer8',
      },
      {
        question: 'partnerWithUs.faq.questions.question9',
        answer: 'partnerWithUs.faq.answers.answer9',
      },
      {
        question: 'partnerWithUs.faq.questions.question10',
        answer: 'partnerWithUs.faq.answers.answer10',
      },
      {
        question: 'partnerWithUs.faq.questions.question11',
        answer: 'partnerWithUs.faq.answers.answer11',
      },
    ],
  },
  {
    service: 'digitaler-nachlass',
    questions: [
      {
        question: 'digitalFootprintLanding.faq.questions.question1',
        answer: 'digitalFootprintLanding.faq.answers.answer1',
      },
      {
        question: 'digitalFootprintLanding.faq.questions.question2',
        answer: 'digitalFootprintLanding.faq.answers.answer2',
      },
      {
        question: 'digitalFootprintLanding.faq.questions.question3',
        answer: 'digitalFootprintLanding.faq.answers.answer3',
      },
      {
        question: 'digitalFootprintLanding.faq.questions.question4',
        answer: 'digitalFootprintLanding.faq.answers.answer4',
      },
      {
        question: 'digitalFootprintLanding.faq.questions.question5',
        answer: 'digitalFootprintLanding.faq.answers.answer5',
      },
      {
        question: 'digitalFootprintLanding.faq.questions.question6',
        answer: 'digitalFootprintLanding.faq.answers.answer6',
      },
      {
        question: 'digitalFootprintLanding.faq.questions.question7',
        answer: 'digitalFootprintLanding.faq.answers.answer7',
      },
      {
        question: 'digitalFootprintLanding.faq.questions.question8',
        answer: 'digitalFootprintLanding.faq.answers.answer8',
      },
      {
        question: 'digitalFootprintLanding.faq.questions.question9',
        answer: 'digitalFootprintLanding.faq.answers.answer9',
      },
      {
        question: 'digitalFootprintLanding.faq.questions.question10',
        answer: 'digitalFootprintLanding.faq.answers.answer10',
      },
      {
        question: 'digitalFootprintLanding.faq.questions.question11',
        answer: 'digitalFootprintLanding.faq.answers.answer11',
      },
    ],
  },
  {
    service: 'vermoegens-und-sachregister',
    questions: [
      {
        question: 'registerAssets.faq.questions.question1',
        answer: 'registerAssets.faq.answers.answer1',
      },
      {
        question: 'registerAssets.faq.questions.question2',
        answer: 'registerAssets.faq.answers.answer2',
      },
      {
        question: 'registerAssets.faq.questions.question3',
        answer: 'registerAssets.faq.answers.answer3',
      },
      {
        question: 'registerAssets.faq.questions.question4',
        answer: 'registerAssets.faq.answers.answer4',
      },
      {
        question: 'registerAssets.faq.questions.question5',
        answer: 'registerAssets.faq.answers.answer5',
      },
      {
        question: 'registerAssets.faq.questions.question6',
        answer: 'registerAssets.faq.answers.answer6',
      },
      {
        question: 'registerAssets.faq.questions.question7',
        answer: 'registerAssets.faq.answers.answer7',
      },
      {
        question: 'registerAssets.faq.questions.question8',
        answer: 'registerAssets.faq.answers.answer8',
      },
      {
        question: 'registerAssets.faq.questions.question9',
        answer: 'registerAssets.faq.answers.answer9',
      },
    ],
  },
];

export const whyWithUs = [
  {
    title: 'provisionLanding.whyWithUs.cards.card1.title',
    description: 'provisionLanding.whyWithUs.cards.card1.description',
    icon: '/assets/icons/whyUs1.svg',
    service: 'main',
  },
  {
    title: 'provisionLanding.whyWithUs.cards.card2.title',
    description: 'provisionLanding.whyWithUs.cards.card2.description',
    icon: '/assets/icons/whyUs2.svg',
    service: 'main',
  },
  {
    title: 'provisionLanding.whyWithUs.cards.card3.title',
    description: 'provisionLanding.whyWithUs.cards.card3.description',
    icon: '/assets/icons/whyUs3.svg',
    service: 'main',
  },
];

export const whatIs = [
  {
    title: 'provisionLanding.aboutCards.remainInControl.title',
    description: 'provisionLanding.aboutCards.remainInControl.description',
    icon: '/assets/icons/understandable.svg',
    service: 'main',
  },
  {
    title: 'provisionLanding.aboutCards.protectYourFamily.title',
    description: 'provisionLanding.aboutCards.protectYourFamily.description',
    icon: '/assets/icons/doneFast.svg',
    service: 'main',
  },
  {
    title: 'provisionLanding.aboutCards.relieveRelatives.title',
    description: 'provisionLanding.aboutCards.relieveRelatives.description',
    icon: '/assets/icons/inexpensive.svg',
    service: 'main',
  },
  {
    title: 'livingWillLanding.aboutCards.proactiveMeasures.title',
    description: 'livingWillLanding.aboutCards.proactiveMeasures.description',
    icon: '/assets/icons/proactiveMeasure.svg',
    service: 'patientenverfuegung',
  },
  {
    title: 'livingWillLanding.aboutCards.wishesYouCanAdapt.title',
    description: 'livingWillLanding.aboutCards.wishesYouCanAdapt.description',
    icon: '/assets/icons/wishesYouCanAdapt.svg',
    service: 'patientenverfuegung',
  },
  {
    title: 'livingWillLanding.aboutCards.legalSecurity.title',
    description: 'livingWillLanding.aboutCards.legalSecurity.description',
    icon: '/assets/icons/inexpensive.svg',
    service: 'patientenverfuegung',
  },
  {
    title: 'poaLanding.aboutCards.guaranteeForPower.title',
    description: 'poaLanding.aboutCards.guaranteeForPower.description',
    icon: '/assets/icons/guaranteeForPower.svg',
    service: 'vorsorgeauftrag',
  },
  {
    title: 'poaLanding.aboutCards.mutualProtection.title',
    description: 'poaLanding.aboutCards.mutualProtection.description',
    icon: '/assets/icons/mutualProtection.svg',
    service: 'vorsorgeauftrag',
  },
  {
    title: 'poaLanding.aboutCards.clarificationForFamily.title',
    description: 'poaLanding.aboutCards.clarificationForFamily.description',
    icon: '/assets/icons/inexpensive.svg',
    service: 'vorsorgeauftrag',
  },
  {
    title: 'lastWillLanding.aboutCards.whoGetsWhat.title',
    description: 'lastWillLanding.aboutCards.whoGetsWhat.description',
    icon: '/assets/icons/whoGetsWhat.svg',
    service: 'testament',
  },
  {
    title: 'lastWillLanding.aboutCards.enforceWishes.title',
    description: 'lastWillLanding.aboutCards.enforceWishes.description',
    icon: '/assets/icons/enforceWishes.svg',
    service: 'testament',
  },
  {
    title: 'lastWillLanding.aboutCards.clarificationForFamily.title',
    description:
      'lastWillLanding.aboutCards.clarificationForFamily.description',
    icon: '/assets/icons/inexpensive.svg',
    service: 'testament',
  },
  {
    title: 'marriageContractLanding.aboutCards.protectSpouse.title',
    description: 'marriageContractLanding.aboutCards.protectSpouse.description',
    icon: '/assets/icons/doneFast.svg',
    service: 'ehevertrag',
  },
  {
    title: 'marriageContractLanding.aboutCards.winClarity.title',
    description: 'marriageContractLanding.aboutCards.winClarity.description',
    icon: '/assets/icons/notes.svg',
    service: 'ehevertrag',
  },
  {
    title: 'marriageContractLanding.aboutCards.saveDiscussions.title',
    description:
      'marriageContractLanding.aboutCards.saveDiscussions.description',
    icon: '/assets/icons/3p.svg',
    service: 'ehevertrag',
  },
  {
    title: 'partnerWithUs.aboutCards.differentiate.title',
    description: 'partnerWithUs.aboutCards.differentiate.description',
    icon: '/assets/icons/differentiate.svg',
    service: 'partner-werden',
  },
  {
    title: 'partnerWithUs.aboutCards.efficiency.title',
    description: 'partnerWithUs.aboutCards.efficiency.description',
    icon: '/assets/icons/efficiency.svg',
    service: 'partner-werden',
  },
  {
    title: 'partnerWithUs.aboutCards.simplyIntegrated.title',
    description: 'partnerWithUs.aboutCards.simplyIntegrated.description',
    icon: '/assets/icons/simplyintegrated.svg',
    service: 'partner-werden',
  },
  {
    title: 'digitalFootprintLanding.aboutCards.documentAccounts.title',
    description:
      'digitalFootprintLanding.aboutCards.documentAccounts.description',
    icon: '/assets/icons/proactiveMeasure.svg',
    service: 'digitaler-nachlass',
  },
  {
    title: 'digitalFootprintLanding.aboutCards.connectDigitalValues.title',
    description:
      'digitalFootprintLanding.aboutCards.connectDigitalValues.description',
    icon: '/assets/icons/wishesYouCanAdapt.svg',
    service: 'digitaler-nachlass',
  },
  {
    title: 'digitalFootprintLanding.aboutCards.preserveDigitalData.title',
    description:
      'digitalFootprintLanding.aboutCards.preserveDigitalData.description',
    icon: '/assets/icons/inexpensive.svg',
    service: 'digitaler-nachlass',
  },
  {
    title: 'registerAssets.aboutCards.logPhysicalAssets.title',
    description: 'registerAssets.aboutCards.logPhysicalAssets.description',
    icon: '/assets/icons/proactiveMeasure.svg',
    service: 'vermoegens-und-sachregister',
  },
  {
    title: 'registerAssets.aboutCards.outlineRelationships.title',
    description: 'registerAssets.aboutCards.outlineRelationships.description',
    icon: '/assets/icons/wishesYouCanAdapt.svg',
    service: 'vermoegens-und-sachregister',
  },
  {
    title: 'registerAssets.aboutCards.simplifyCleanup.title',
    description: 'registerAssets.aboutCards.simplifyCleanup.description',
    icon: '/assets/icons/inexpensive.svg',
    service: 'vermoegens-und-sachregister',
  },
  {
    title: 'grosseItern.aboutCards.justDoIt.title',
    description: 'grosseItern.aboutCards.justDoIt.description',
    icon: '/assets/icons/understandable.svg',
    service: 'grosseltern-magazin',
  },
  {
    title: 'grosseItern.aboutCards.security.title',
    description: 'grosseItern.aboutCards.security.description',
    icon: '/assets/icons/doneFast.svg',
    service: 'grosseltern-magazin',
  },
  {
    title: 'grosseItern.aboutCards.done.title',
    description: 'grosseItern.aboutCards.done.description',
    icon: '/assets/icons/20minutes.svg',
    service: 'grosseltern-magazin',
  },
  {
    title: 'grosseItern.aboutCards.swissProvider.title',
    description: 'grosseItern.aboutCards.swissProvider.description',
    icon: '/assets/icons/swissProvider.svg',
    service: 'grosseltern-magazin',
  },
];

export const testimonials = [
  {
    name: 'provisionLanding.testimonials.testimonial1.name',
    testimonial: 'provisionLanding.testimonials.testimonial1.text',
  },
  {
    name: 'provisionLanding.testimonials.testimonial2.name',
    testimonial: 'provisionLanding.testimonials.testimonial2.text',
  },
  {
    name: 'provisionLanding.testimonials.testimonial3.name',
    testimonial: 'provisionLanding.testimonials.testimonial3.text',
  },
];

export const marriageContractTestimonials = [
  {
    name: 'marriageContractLanding.testimonials.testimonial1.name',
    testimonial: 'marriageContractLanding.testimonials.testimonial1.text',
  },
  {
    name: 'marriageContractLanding.testimonials.testimonial2.name',
    testimonial: 'marriageContractLanding.testimonials.testimonial2.text',
  },
  {
    name: 'marriageContractLanding.testimonials.testimonial3.name',
    testimonial: 'marriageContractLanding.testimonials.testimonial3.text',
  },
];

export const digitalFootprintTestimonials = [
  {
    name: 'digitalFootprintLanding.testimonials.testimonial1.name',
    testimonial: 'digitalFootprintLanding.testimonials.testimonial1.text',
  },
  {
    name: 'digitalFootprintLanding.testimonials.testimonial2.name',
    testimonial: 'digitalFootprintLanding.testimonials.testimonial2.text',
  },
  {
    name: 'digitalFootprintLanding.testimonials.testimonial3.name',
    testimonial: 'digitalFootprintLanding.testimonials.testimonial3.text',
  },
];

export const registerOfAssetsTestimonials = [
  {
    name: 'registerAssets.testimonials.testimonial1.name',
    testimonial: 'registerAssets.testimonials.testimonial1.text',
  },
  {
    name: 'registerAssets.testimonials.testimonial2.name',
    testimonial: 'registerAssets.testimonials.testimonial2.text',
  },
  {
    name: 'registerAssets.testimonials.testimonial3.name',
    testimonial: 'registerAssets.testimonials.testimonial3.text',
  },
];

export const legallyCompliant = [
  {
    title: 'main',
    bannerHeading: 'provisionLanding.subBanner.title',
    bannerSubHeading: 'provisionLanding.subBanner.subtitle',
    bannerImage: '/assets/images/legallyComplaint.webp',
    bannerButton: 'provisionLanding.subBanner.buttonLabel',
    url: '/vorsorge',
    imagePosition: 'left',
  },
  {
    title: 'patientenverfuegung',
    bannerHeading: 'livingWillLanding.subBanner.title',
    bannerSubHeading: 'livingWillLanding.subBanner.subtitle',
    bannerImage: '/assets/images/livingWillBanner.webp',
    bannerButton: 'livingWillLanding.subBanner.buttonLabel',
    url: '/vorsorge',
    imagePosition: 'left',
  },
  {
    title: 'vorsorgeauftrag',
    bannerHeading: 'poaLanding.subBanner.title',
    bannerSubHeading: 'poaLanding.subBanner.subtitle',
    bannerImage: '/assets/images/powerOfAttorneyBanner.webp',
    bannerButton: 'poaLanding.subBanner.buttonLabel',
    url: '/vorsorge',
    imagePosition: 'left',
  },
  {
    title: 'testament',
    bannerHeading: 'lastWillLanding.subBanner.title',
    bannerSubHeading: 'lastWillLanding.subBanner.subtitle',
    bannerImage: '/assets/images/lastWillBanner.webp',
    bannerButton: 'lastWillLanding.subBanner.buttonLabel',
    url: '/vorsorge',
    imagePosition: 'left',
  },
  {
    title: 'ehevertrag',
    bannerHeading: 'marriageContractLanding.subBanner.title',
    bannerSubHeading: 'marriageContractLanding.subBanner.subtitle',
    bannerImage: '/assets/images/marriageContractBanner.webp',
    bannerButton: 'marriageContractLanding.subBanner.buttonLabel',
    url: '/vorsorge',
    imagePosition: 'left',
  },
  {
    title: 'partner-werden',
    bannerHeading: 'partnerWithUs.subBanner.title',
    bannerSubHeading: 'partnerWithUs.subBanner.subtitle',
    bannerImage: '/assets/images/partnerWithUsBanner.webp',
    imagePosition: 'right',
  },
  {
    title: 'digitaler-nachlass',
    bannerHeading: 'digitalFootprintLanding.subBanner.title',
    bannerSubHeading: 'digitalFootprintLanding.subBanner.subtitle',
    bannerImage: '/assets/images/digitalFootprintBanner.webp',
    bannerButton: 'digitalFootprintLanding.subBanner.buttonLabel',
    imagePosition: 'left',
    url: '/vorsorge',
  },
  {
    title: 'vermoegens-und-sachregister',
    bannerHeading: 'registerAssets.subBanner.title',
    bannerSubHeading: 'registerAssets.subBanner.subtitle',
    bannerImage: '/assets/images/registerAssetsBanner.webp',
    bannerButton: 'registerAssets.subBanner.buttonLabel',
    imagePosition: 'left',
    url: '#join-waitlist',
  },
];

export const pricings = [
  {
    title: 'Starter',
    productId: 'starter',
    price: '399',
    promo_price: '199.5',
    finfinder_price: '199',
    finfinderSale: 'provisionLanding.pricing.starter.finfinderSale',
    features: [
      'provisionLanding.pricing.starter.features.0',
      'provisionLanding.pricing.starter.features.1',
      'provisionLanding.pricing.starter.features.2',
      'provisionLanding.pricing.starter.features.3',
    ],
  },
  {
    title: 'Standard',
    productId: 'advanced',
    price: '699',
    promo_price: '349.5',
    finfinder_price: '299',
    finfinderSale: 'provisionLanding.pricing.standard.finfinderSale',
    features: [
      'provisionLanding.pricing.standard.features.0',
      'provisionLanding.pricing.standard.features.1',
      'provisionLanding.pricing.standard.features.2',
      'provisionLanding.pricing.standard.features.3',
      'provisionLanding.pricing.standard.features.4',
      'provisionLanding.pricing.standard.features.5',
      'provisionLanding.pricing.standard.features.6',
    ],
    bestSeller: true,
  },
  {
    title: 'Family Package',
    productId: 'family',
    price: '399',
    promo_price: '199.5',
    finfinder_price: '199',
    finfinderSale: 'provisionLanding.pricing.starter.finfinderSale',
    features: [
      'provisionLanding.pricing.standard.features.0',
      'provisionLanding.pricing.standard.features.1',
      'provisionLanding.pricing.standard.features.2',
      'provisionLanding.pricing.standard.features.3',
      'provisionLanding.pricing.standard.features.4',
      'provisionLanding.pricing.standard.features.5',
      'provisionLanding.pricing.standard.features.6',
    ],
    priceSale: true,
  },
];
export const pensionProvisionBanner = {
  title: 'main',
  bannerHeading: 'provisionLanding.provisionBanner.title',
  bannerSubHeading: 'provisionLanding.provisionBanner.subtitle',
  bannerButton: 'provisionLanding.provisionBanner.buttonLabel',
  bannerImage: '/assets/images/mainBanner.webp',
  url: '/vorsorge',
};

export const newsLetters = [
  {
    service: 'main',
    title: 'provisionLanding.newsletter.title',
    buttonLabel: 'provisionLanding.newsletter.buttonLabel',
    url: '/vorsorge',
  },
  {
    service: 'patientenverfuegung',
    title: 'livingWillLanding.newsletter.title',
    buttonLabel: 'livingWillLanding.newsletter.buttonLabel',
    url: '/vorsorge',
  },
  {
    service: 'vorsorgeauftrag',
    title: 'poaLanding.newsletter.title',
    buttonLabel: 'poaLanding.newsletter.buttonLabel',
    url: '/vorsorge',
  },
  {
    service: 'testament',
    title: 'lastWillLanding.newsletter.title',
    buttonLabel: 'lastWillLanding.newsletter.buttonLabel',
    url: '/vorsorge',
  },
  {
    service: 'digitaler-nachlass',
    title: 'digitalFootprintLanding.newsletter.title',
    buttonLabel: 'digitalFootprintLanding.newsletter.buttonLabel',
    url: '/vorsorge',
  },
  {
    service: 'vermoegens-und-sachregister',
    title: 'registerAssets.newsletter.title',
    buttonLabel: 'registerAssets.newsletter.buttonLabel',
    url: '/vorsorge',
  },
];

export const footerLinks = [
  {
    title: 'provisions',
    i18nKey: 'provisionLinks',
    links: [
      {
        title: 'Pricing',
        link: '/',
        i18nKey: 'pricing',
      },
      {
        title: 'Living will',
        link: '/patientenverfuegung',
        i18nKey: 'livingWill',
      },
      {
        title: 'Power of attorney',
        link: '/vorsorgeauftrag',
        i18nKey: 'powerOfAttorney',
      },
      {
        title: 'Last will',
        link: '/testament',
        i18nKey: 'lastWill',
      },
      {
        title: 'Digital footprint',
        link: '/digitaler-nachlass',
        i18nKey: 'digitalFootprint',
      },
      {
        title: 'Register of assets',
        link: '/vermoegens-und-sachregister',
        i18nKey: 'registerAssets',
      },
    ],
  },
  {
    title: 'resources',
    i18nKey: 'resourcesLinks',
    links: [
      {
        title: 'Imprint',
        link: '/impressum',
        i18nKey: 'imprint',
      },
      {
        title: 'Privacy policy',
        link: '/datenschutzbestimmungen',
        i18nKey: 'privacyPolicy',
      },
      {
        title: 'Terms and conditions',
        link: '/agb',
        i18nKey: 'termsAndConditions',
      },
      {
        title: 'Contact',
        link: '/kontakt',
        i18nKey: 'contact',
      },
    ],
  },
];
