import { Theme } from '@mui/material/styles';
import Card from './Card';
import Tabs from './Tabs';
import DataGrid from './DataGrid';
import List from './List';
import Switch from './Switch';
import Select from '#/theme/overrides/Select';

// Define the shape of the overrides object.
// This would depend on what your Card and Tabs overrides look like.
interface Overrides {
  [key: string]: any;
}

function mergeComponentOverrides(...overrides: Overrides[]): Overrides {
  return overrides.reduce((result, current) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(current)) {
      if (result[key]) {
        // eslint-disable-next-line no-param-reassign
        result[key] = { ...result[key], ...value };
      } else {
        // eslint-disable-next-line no-param-reassign
        result[key] = value;
      }
    }
    return result;
  }, {});
}

export default function ComponentsOverrides(theme: Theme): Overrides {
  return mergeComponentOverrides(
    Card(theme),
    Tabs(theme),
    DataGrid(theme),
    List(theme),
    Switch(theme),
    Select
  );
}
