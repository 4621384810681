import numeral from 'numeral';

export function fCurrency(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}

export function fSwissNumber(number: string | number) {
  let formatted = numeral(number).format('0,0');
  formatted = formatted.replace(/,/g, "'");
  return formatted;
}

export function fSwissThousandSeparator(num: string) {
  const cleanedNum = num.replace(/'/g, '');

  return cleanedNum.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

export function resetSwissSeparator(num: string) {
  if (!num) return null;
  return num.replace(/'/g, '');
}
