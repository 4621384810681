import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';
import { useSwitchAsGuest } from '#/api/successor';
import useAccountSwitch from '#/hooks/useAccountSwitch';
import useAuth from '#/hooks/useAuth';

type Props = {
  id?: number;
  first_name: string;
  last_name: string;
  profile_picture: string;
  isMain: boolean;
  closeModal: () => void;
};

export default function AccountRow({
  id,
  first_name,
  last_name,
  profile_picture,
  isMain,
  closeModal,
}: Props) {
  const { translate } = useLocales();
  const { user } = useAuth();
  const { handleSwitchAccount, currentAccount } = useAccountSwitch();
  const navigate = useNavigate();
  const { mutateAsync: switchAsGuest, isLoading: isSwitching } =
    useSwitchAsGuest();

  const onAccSwitch = async (id: number | undefined) => {
    await switchAsGuest({ id }).then((res) => {
      handleSwitchAccount(res?.data);
      closeModal();
      if (id === user?.id) {
        localStorage.removeItem('guest_on');
        localStorage.removeItem('ownerID');
        if (!user?.primary_account) {
          navigate(`/dashboard/gast-bei/${currentAccount?.id}`);
        } else {
          navigate('/dashboard/home');
        }
      } else {
        navigate(`/dashboard/gast-bei/${id}`);
      }
    });
  };

  return (
    <Box
      sx={{
        paddingX: 2.5,
        paddingY: 1.5,
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{
          marginLeft: 2,
        }}
      >
        <Avatar
          src={profile_picture}
          sx={{
            width: 55,
            height: 55,
          }}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width={1}
          spacing={2}
        >
          <Stack direction="column">
            <Typography variant="subtitle1" sx={{ lineHeight: 1 }} noWrap>
              {first_name} {last_name}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body2" noWrap color="gray">
                {String(translate('global.account'))}:
              </Typography>
              <Typography variant="body2">
                {isMain
                  ? String(translate('global.main'))
                  : String(translate('global.guest'))}
              </Typography>
            </Stack>
          </Stack>
          <Button
            variant={id ? 'outlined' : 'contained'}
            color="primary"
            size="small"
            onClick={() => onAccSwitch(id)}
            startIcon={
              isSwitching ? (
                <Iconify icon="line-md:loading-twotone-loop" />
              ) : null
            }
          >
            <Typography variant="body2">
              {String(translate('global.switch'))}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
