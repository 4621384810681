import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Toolbar,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Logo from '../Logo';
import Navbar from '../navbar';
import { bgBlur } from '#/utils/cssStyles';
import AccountPopover from './AccountPopover';
import accPopoverMenu from './accPopoverMenu';
import useAuth from '#/hooks/useAuth';
import useResponsive from '#/hooks/useResponsive';
import Iconify from '../Iconify';
import LanguagePopover from './LanguagePopover';
import useLocales from '#/hooks/useLocales';
import SwitchPopover from '#/components/shared/ui/header/SwitchPopover';
import useAccountSwitch from '#/hooks/useAccountSwitch';
import { useListMyConnections } from '#/api/successor';
// import NotificationPopover from './NotificationPopover';

type Props = {
  openNav: boolean;
  onOpenNav: VoidFunction;
  onCloseNav: VoidFunction;
};

const Header = ({ onOpenNav, onCloseNav, openNav }: Props) => {
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [switchAccountOpen, setSwitchAccountOpen] = useState<boolean>(false);
  const [hideSwitch, setHideSwitch] = useState<boolean>(false);

  const theme = useTheme();
  const { translate } = useLocales();
  const { user, logout, isAuthenticated } = useAuth();
  const { currentAccount } = useAccountSwitch();
  const { data: connectionsList } = useListMyConnections(!!user?.id);

  const handleOpenSwitchAccount = () => setSwitchAccountOpen(true);
  const handleCloseSwitchAccount = () => setSwitchAccountOpen(false);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  useEffect(() => {
    if (connectionsList?.length === 0) {
      setHideSwitch(true);
    }
  }, [connectionsList]);

  const guestOn = !!localStorage.getItem('guest_on');
  const isMainAccount = currentAccount?.id === user?.id;
  const hideSwitchAccount = guestOn || !(user?.primary_account && hideSwitch);
  const isDesktop = useResponsive('up', 'md');

  const renderContent = (
    <Stack
      direction="row"
      justifyContent="space-between"
      width={1}
      alignItems="center"
      maxWidth="1264px"
      margin="0 auto"
    >
      <Logo />
      <Stack
        direction="row"
        spacing={{
          xs: 1,
          md: 2,
        }}
        alignItems="center"
      >
        <Navbar openNav={openNav} onCloseNav={onCloseNav} />
        <LanguagePopover />

        {isAuthenticated && user && (
          <>
            {/* <NotificationPopover /> */}
            <Avatar
              src={currentAccount?.profile_picture}
              onClick={handleOpenMenu}
            />

            <AccountPopover
              openMenu={openMenu}
              handleCloseMenu={handleCloseMenu}
              actions={accPopoverMenu
                .filter((item) => isMainAccount || item.title !== 'settings')
                .filter(
                  (item) => hideSwitchAccount || item.title !== 'changeAccount'
                )
                .map((item) => (
                  <Box key={item.title}>
                    {item.title === 'logOut' ? (
                      <MenuItem onClick={logout}>
                        {String(translate(`global.settings.${item.title}`))}
                      </MenuItem>
                    ) : (
                      <Box>
                        {item.title === 'changeAccount' ? (
                          <MenuItem onClick={handleOpenSwitchAccount}>
                            {String(translate(`global.settings.${item.title}`))}
                          </MenuItem>
                        ) : (
                          <Link
                            // @ts-expect-error
                            to={item.link}
                            style={{
                              textDecoration: 'none',
                              color: 'inherit',
                            }}
                          >
                            <MenuItem onClick={handleCloseMenu}>
                              {String(
                                translate(`global.settings.${item.title}`)
                              )}
                            </MenuItem>
                          </Link>
                        )}
                      </Box>
                    )}
                    {accPopoverMenu.indexOf(item) !==
                      accPopoverMenu.length - 1 && (
                      <Divider sx={{ borderStyle: 'dashed' }} />
                    )}
                  </Box>
                ))}
            />
          </>
        )}
        {!isDesktop && (
          <IconButton onClick={onOpenNav} aria-label="hamburger-menu">
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        )}
      </Stack>
      <SwitchPopover
        open={switchAccountOpen}
        closeModal={handleCloseSwitchAccount}
      />
    </Stack>
  );

  return (
    <AppBar
      sx={{
        px: { sx: 2, md: 3, lg: 5 },
        // py: 2,
        width: '100%',
        height: 70,
        boxShadow: 'none',
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
