const adminDashboard = {
  main: {
    title: 'Dashboard',
    description:
      'Sehen Sie, wie viele Ihrer Kunden gut geregelt besuchen, ein Login erstellen und dann auch ein Paket kaufen.',
  },
  analytics: {
    cards: {
      visitors: 'Besucher',
      conversion_rate_login: 'Konversionsrate zu Login',
      loginsCreated: 'Logins erstellt',
      conversion_rate_pay: 'Konversionsrate zu zahlenden Kunden',
      paying_customers: 'Zahlende Kunden',
    },
  },
  charts: {
    visitors: 'Besucher',
    conversionRateToLogin: 'Konversionsrate Zu Login',
    loginsCreated: 'Logins erstellt',
    conversionRateToPaying: 'Konversionsrate zu zahlenden Kunden',
    subscriptionsCreated: 'Zahlende Kunden',
  },
  historicalData: 'Historische Daten',
  historicalDataSubheader:
    '({{subscription_growth_percentage}}%) als im letzten Jahr',
  demographics: 'Demografie',
  women: 'Frauen',
  man: 'Männer',
  visits: 'besuche',

  widgetsOptions: {
    lifeExpectancy: 'Lebenserwartung',
    ageAppropriate: 'Altersgerechte 3a-Anlage',
    ahvCalculator: 'AHV-Rechner',
  },

  clientOverview: 'Kundenübersicht',
  generalOverview: 'Dashboard',
  legalAdvisors: 'Rechtsberater',
  financialAdvisors: 'Finanzberater',
  platform: 'Plattform',
  user: 'Benutzer',
  mainInfoModal: {
    title: 'Bitte fehlende Informationen ausfüllen',
    description:
      'Bitte ergänzen Sie Ihren Vornamen und Namen, um das Login abzuschliessen.',
  },

  consulting: {
    unlockWidget: {
      title: 'Widget freischalten',
      description:
        'Fordern Sie ein Angebot an und aktivieren Sie Widgets für Sie und Ihre Berater, um Kunden während der Beratungsgespräche eine vereinfachte Rentenberatung zu bieten.',
    },
  },
  teamTable: {
    tableHeads: {
      name: 'Name',
      email: 'Email',
      role: 'Rolle',
      last_login: 'Letzter Login',
    },
  },
  consultantTable: {
    defaultSwitchTooltip:
      'Beim Wechsel zur Standardliste werden nur die von Gutgeregelt bereitgestellten Berater angezeigt.',
    tableHeads: {
      name: 'Name',
      email: 'Email',
      location: 'Standort',
      added: 'Hinzugefügt',
    },
  },
  usersTable: {
    toolbarButtons: {
      columns: 'Spalten',
      export: 'Exportieren',
      filter: 'Filter',
      transferSelectedUsers:
        'Ausgewählte Benutzer übertragen ({{selectedUsers}})',
      removeFromConsulting: 'Aus Beratung entfernen',
    },
    tableHeads: {
      category: 'Kategorie',
      name: 'Name',
      email: 'Email',
      owned_by: 'Berater',
      last_login: 'Letzter Login',
      pension_provision: 'Vorsorge',
      living_will: 'Patientenverfügung',
      power_of_attorney: 'Vorsorgeauftrag',
      last_will: 'Testament',
    },
  },
  deleteUser: 'Benutzer löschen',
  deleteConsultant: {
    title:
      'Sie sind dabei, {{consultant}} aus der Liste zu entfernen. Sind Sie sicher?',
    description: 'Bitte bestätigen Sie das Löschen.',
    onConfirm: 'Löschen',
    onClose: 'Abbrechen',
  },
  deleteTeamMember: {
    title: 'Sie sind dabei, die Liste zu löschen. Sind Sie sicher?',
    description: 'Bitte bestätigen Sie das Löschen.',
    onConfirm: 'Löschen',
    onClose: 'Abbrechen',
  },
  cancelInvitation: {
    title: 'Sie sind dabei, die Einladung abzubrechen. Sind Sie sicher?',
    description:
      'Auch nach der Kündigung können Sie das Mitglied erneut einladen..',
    onConfirm: 'Löschen',
    onClose: 'Abbrechen',
  },
  deleteUserModal: {
    title:
      'Sie sind dabei, {{user}} aus der Liste zu entfernen. Sind Sie sicher?',
    description: 'Bitte bestätigen Sie das Löschen.',
    onConfirm: 'Löschen',
    onClose: 'Abbrechen',
  },

  userTransferModal: {
    title:
      'Sie haben die unten aufgeführten Benutzer ausgewählt, um sie auf einen anderen Berater zu übertragen',
    description:
      'Wählen Sie aus der Liste unten den Berater aus, auf den übertragen werden soll',
  },

  confirmUnassignment: {
    title:
      'Bestätigen Sie, dass die Zuweisung des Beraters für die ausgewählten Benutzer entfernt wird?',
    description: 'Sie können sie später einem anderen Berater zuweisen.',
    onConfirm: 'Bestätigen',
    onClose: 'Abbrechen',
  },
};

export default adminDashboard;
