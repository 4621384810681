import { Collapse, List, Stack, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';
import { Item } from '#/components/shared/ui/navbar/navConfig';
import { getActive } from '#/components/shared/ui/navbar/NavItem';
import Iconify from '#/components/shared/ui/Iconify';

type MobileMegaMenuItemProps = {
  mobileCollapse: boolean;
  items: Item[];
};

export default function MobileMegaMenuItem({
  mobileCollapse,
  items,
}: MobileMegaMenuItemProps) {
  return (
    <Collapse in={mobileCollapse} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {items.map((item) => (
          <CollapseItem item={item} key={item.title} />
        ))}
      </List>
    </Collapse>
  );
}

const CollapseItem = ({ item }: { item: Item }) => {
  const { translate } = useLocales();

  const { url, title } = item;

  const { pathname } = useLocation();

  const active = getActive(url, pathname);

  return (
    <Stack
      direction="row"
      alignItems="center"
      component={Link}
      key={item.title}
      to={item.url}
      sx={{
        pl: 3,
        py: 0.5,
        color: 'text.primary',
        textDecoration: 'none !important',
        opacity: 0.5,
        ...(active && {
          pl: 1,
          opacity: 1,
          color: 'primary.main',
        }),
      }}
    >
      {active && <Iconify icon="oui:dot" />}
      <Typography noWrap>{String(translate(title))}</Typography>
    </Stack>
  );
};
