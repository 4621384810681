import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, BoxProps } from '@mui/material';
import useResponsive from '#/hooks/useResponsive';
import useAuth from '#/hooks/useAuth';
import {
  useGetPartnerSettings,
  useGetPublicPartnerSettings,
} from '#/api/partnerQueries';
import Iconify from '#/components/shared/ui/Iconify';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx }) => {
    const isDesktop = useResponsive('up', 'md');
    const { isAuthenticated, user } = useAuth();

    const isAdminLoggedIn =
      user?.partner?.role === 'admin' || user?.partner?.role === 'owner';

    const { hostname } = window.location;
    const domainParts = hostname.split('.');
    const subdomain =
      domainParts.length > 2 ? domainParts.slice(0, -2).join('.') : '';

    const validSubdomain =
      subdomain !== 'www' &&
      subdomain !== 'localhost' &&
      subdomain !== 'dev' &&
      subdomain !== '';

    const { data: byAdmin } = useGetPartnerSettings(isAdminLoggedIn);
    const { data: partnerTheme, isLoading: gettingSettings } =
      useGetPublicPartnerSettings(subdomain, validSubdomain);

    const isFromSmart = localStorage.getItem('gg_partner_key') === 'finfinder';
    const partnerLogo = byAdmin?.image || partnerTheme?.image;

    if (gettingSettings) {
      return (
        <Iconify
          icon="eos-icons:three-dots-loading"
          color="primary.main"
          width={isDesktop ? 250 : 150}
          height={isDesktop ? 70 : 40}
        />
      );
    }

    const logo = (
      <Box
        component="img"
        src={
          isFromSmart
            ? '/assets/logos/finfinder_logo.svg'
            : partnerLogo || '/assets/logos/gutgeregeltLogo.svg'
        }
        sx={{
          width: isDesktop ? 250 : 150,
          height: isDesktop ? 70 : 40,
          cursor: 'pointer',
          objectFit: 'contain',
          ...sx,
        }}
        alt="Gut geregelt"
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link
        component={RouterLink}
        to={isAuthenticated ? '/dashboard/home' : '/'}
        sx={{ display: 'contents' }}
      >
        {logo}
      </Link>
    );
  }
);

export default Logo;
