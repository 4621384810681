import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField, TextFieldProps, useTheme } from '@mui/material';
import useLocales from '#/hooks/useLocales';

type IProps = {
  name: string;
};

type Props = IProps & TextFieldProps;

export default function RHFTextField({ name, helperText, ...other }: Props) {
  const { control } = useFormContext();
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={
            typeof field.value === 'number' && field.value === 0
              ? ''
              : field.value
          }
          onChange={(e) => field.onChange(e)}
          error={!!error}
          helperText={error ? String(translate(error?.message)) : helperText}
          InputProps={{
            ...other.InputProps,
          }}
          {...other}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme.palette.divider,
              },
            },
          }}
        />
      )}
    />
  );
}
