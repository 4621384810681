import queryClient from '#/api/query-client';
import { useDelete, useGet, usePost, usePut } from '#/api/reactQuery';
import { IServiceStats } from '#/types/globalTypes';
import { IGoods, IPropertyAsset } from '#/types/propertyAssets';

const baseURL = '/services';

export const ServiceEndpoints = {
  getServicesStats: () => `${baseURL}/stats/`,
  startService: (serviceName: string) => `${baseURL}/${serviceName}/`,
  getServiceForm: (serviceName: string) => `${baseURL}/${serviceName}/`,
  submitDocument: (serviceName: string) => `${baseURL}/${serviceName}/`,
  downloadDocument: (documentUrl: string) => `${documentUrl}`,
  deleteDocument: (serviceName: string) =>
    `${baseURL}/${serviceName}/delete-signed-document/`,
  shareSignedDocument: (serviceName: string) =>
    `${baseURL}/${serviceName}/share-signed-document/`,
  getPropertyAssets: (serviceName: string) =>
    `${baseURL}/${serviceName}/estate/list/`,
  getPropertyAsset: (serviceName: string, id: number | string | undefined) =>
    `${baseURL}/${serviceName}/estate/${id}/`,
  addPropertyOrAsset: (serviceName: string) =>
    `${baseURL}/${serviceName}/estate/`,
  updatePropertyAsset: (serviceName: string, id: number | undefined | string) =>
    `${baseURL}/${serviceName}/estate/update/${id}/`,
  deleteEstate: (serviceName: string, id: number | undefined | string) =>
    `${baseURL}/${serviceName}/estate/delete/${id}/`,
  getGoods: (serviceName: string) =>
    `${baseURL}/${serviceName}/inheritance-item/list/`,
  getGoodDetail: (serviceName: string, id: number | string | undefined) =>
    `${baseURL}/${serviceName}/inheritance-item/${id}/`,
  addGood: (serviceName: string) =>
    `${baseURL}/${serviceName}/inheritance-item/`,
  updateGood: (serviceName: string, id: number | string | undefined) =>
    `${baseURL}/${serviceName}/inheritance-item/update/${id}/`,
  deleteGood: (serviceName: string, id: number | string | undefined) =>
    `${baseURL}/${serviceName}/inheritance-item/delete/${id}/`,
  markAsDone: () => `${baseURL}/mark-as-done/`,
};

export const invalidateServicesStatsQueries = {
  getServicesStats: () => {
    queryClient
      .invalidateQueries(ServiceEndpoints.getServicesStats())
      .then((r) => r);
  },
  getServiceForm: (serviceName: string) => {
    queryClient
      .invalidateQueries(ServiceEndpoints.getServiceForm(serviceName))
      .then((r) => r);
  },
  getPropertyEstates: (serviceName: string) => {
    queryClient
      .invalidateQueries(ServiceEndpoints.getPropertyAssets(serviceName))
      .then((r) => r);
  },
  getGoods: (serviceName: string) => {
    queryClient
      .invalidateQueries(ServiceEndpoints.getGoods(serviceName))
      .then((r) => r);
  },
  getPropertyAsset: (serviceName: string, id: number | string | undefined) => {
    queryClient
      .invalidateQueries(ServiceEndpoints.getPropertyAsset(serviceName, id))
      .then((r) => r);
  },
};

export const useGetServicesStats = (enabled: boolean) =>
  useGet<IServiceStats>(
    ServiceEndpoints.getServicesStats(),
    {},
    {
      enabled,
      refetchOnWindowFocus: 'always',
    }
  );

export const useStartService = (serviceName: string) =>
  usePost<any, any>(ServiceEndpoints.startService(serviceName));

export const useGetServiceForm = (serviceName: string, enabled?: boolean) =>
  useGet<any>(
    ServiceEndpoints.getServiceForm(serviceName),
    {},
    {
      enabled,
    }
  );

export const useDownloadDocument = (documentUrl: string) =>
  useGet<any>(ServiceEndpoints.downloadDocument(documentUrl));

export const useSubmitDocument = (serviceName: string) =>
  usePost<any, any>(ServiceEndpoints.submitDocument(serviceName));

export const useDeleteDocument = (serviceName: string) =>
  useDelete(ServiceEndpoints.deleteDocument(serviceName));

export const useShareSignedDocument = (serviceName: string) =>
  usePost<any, any>(ServiceEndpoints.shareSignedDocument(serviceName));

export const useGetPropertyAssets = (serviceName: string) =>
  useGet<IPropertyAsset[]>(ServiceEndpoints.getPropertyAssets(serviceName));

export const useGetPropertyAsset = (
  serviceName: string,
  id: number | string | undefined,
  enabled: boolean
) =>
  useGet<IPropertyAsset>(
    ServiceEndpoints.getPropertyAsset(serviceName, id),
    {},
    { enabled }
  );

export const useAddPropertyOrAsset = (serviceName: string) =>
  usePost<IPropertyAsset, any>(
    ServiceEndpoints.addPropertyOrAsset(serviceName)
  );

export const useUpdatePropertyAsset = (
  serviceName: string,
  id: number | undefined | string
) =>
  usePut<IPropertyAsset, any>(
    ServiceEndpoints.updatePropertyAsset(serviceName, id)
  );

export const useDeleteEstate = (
  serviceName: string,
  id: number | undefined | string
) => useDelete<IPropertyAsset>(ServiceEndpoints.deleteEstate(serviceName, id));

export const useGetGoods = (serviceName: string) =>
  useGet<IGoods[]>(ServiceEndpoints.getGoods(serviceName));

export const useGetGoodDetail = (
  serviceName: string,
  id: number | string | undefined,
  enabled: boolean
) =>
  useGet<IGoods>(
    ServiceEndpoints.getGoodDetail(serviceName, id),
    {},
    { enabled }
  );

export const useAddGood = (serviceName: string) =>
  usePost<IGoods, any>(ServiceEndpoints.addGood(serviceName));

export const useUpdateGood = (
  serviceName: string,
  id: number | string | undefined
) => usePut<IGoods, any>(ServiceEndpoints.updateGood(serviceName, id));

export const useDeleteGood = (
  serviceName: string,
  id: number | string | undefined
) => useDelete<IGoods>(ServiceEndpoints.deleteGood(serviceName, id));

export const useMarkAsDone = () => usePost(ServiceEndpoints.markAsDone());
